<template>
  <mobile-screen :header="true" screen-class="icon-app1 gray-bg">
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="icon-hea1">
        <template v-slot:left>
          <router-link
            :to="{
              name: backLinkName
            }"
          >
            <icon icon="#cx-hea1-arrow-left" />
          </router-link>
        </template>
        <div class="component-title">
          {{
            displayLabelName("profile", "assigned-resources", "release-date")
          }}
        </div>
        <template v-slot:right>
          <button @click="initSubmit">
            <icon icon="#cx-hea1-save" />
          </button>
        </template>
      </top-header-menu-wrapper>
    </template>

    <section>
      <VeeForm
        :key="formKey"
        @submit="submitForm"
        ref="addResourceAvailabilityForm"
        v-slot="{ errors }"
        class="form change-password-form"
        novalidate
      >
        <ul class="clebex-item-section assigned-resource-break">
          <li
            class="clebex-item-section-item"
            :class="{
              'has-error': errors.date_from || errors.date_to
            }"
          >
            <span
              class="error-message"
              v-if="errors.date_from || errors.date_to"
            >
              {{ dateDisplayError(errors) }}
            </span>
            <div class="clebex-item-content-wrapper">
              <ul class="clebex-item-content-range">
                <li class="clebex-item-range from">
                  <button
                    class="clebex-item-range-cta"
                    @click.prevent="openDateRangePicker('from')"
                  >
                    {{ displayDate(selectedDateFromParsed) }}
                  </button>
                </li>
                <li class="clebex-item-range separator">
                  <icon
                    icon="#cx-app1-arrow-right-12x12"
                    width="12"
                    height="12"
                  />
                </li>
                <li class="clebex-item-range to">
                  <button
                    @click.prevent="openDateRangePicker('to')"
                    class="clebex-item-range-cta"
                  >
                    {{ displayDate(selectedDateToParsed) }}
                  </button>
                </li>
              </ul>
            </div>
          </li>
        </ul>
        <ul class="clebex-item-section">
          <li class="clebex-item-section-item overflow-hidden">
            <select-date-range
              :selectDateFn="selectDate"
              :show-date-picker="showDateFrom || showDateTo"
              :dates-to-watch="[selectedDateFrom, selectedDateTo]"
              :selected-dates="[selectedDateFrom, selectedDateTo]"
              :disablePreviousDays="true"
            />
          </li>
        </ul>
        <button type="submit" style="display: none;" ref="submitFormBtn">
          Submit form
        </button>
      </VeeForm>
    </section>
  </mobile-screen>
</template>

<script>
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapActions, mapGetters } from "vuex";
import { formatDate } from "@/services/helpers";
import SelectDateRange from "@/components/global/SelectDateRange";
import dayjs from "dayjs";
import { DateTime } from "luxon";

export default {
  name: "EditAssignedResource",
  data() {
    return {
      showDateFrom: false,
      showDateTo: false,
      selectedDateFrom: false,
      selectedDateTo: false,
      selectedDateFromParsed: null,
      selectedDateToParsed: null
    };
  },
  components: {
    MobileScreen,
    TopHeaderMenuWrapper,
    SelectDateRange
  },
  created() {
    if (this.actionType === "edit") {
      this.getResourceAssignmentBreak({
        resAssignmentId: this.resourceAssignmentId,
        breakId: this.breakId
      });
    }
  },
  computed: {
    ...mapGetters("resourceAssignment", [
      "resourceAssignmentBreak",
      "resourceAssignmentBreaks"
    ]),
    ...mapGetters("settings", ["globalDateFormat"]),
    resourceAssignmentId() {
      const { assignedResourceId } = this.$route.params;
      return assignedResourceId;
    },
    breakId() {
      const { breakId } = this.$route.params;
      return breakId;
    }
  },
  watch: {
    resourceAssignmentBreak: {
      handler(value) {
        this.selectedDateFrom = DateTime.fromISO(value.datetime_from).toFormat(
          "yyyy-dd-MM"
        );
        this.selectedDateTo = DateTime.fromISO(value.datetime_to).toFormat(
          "yyyy-dd-MM"
        );

        this.selectedDateFromParsed = dayjs(
          value.datetime_from,
          "YYYY-MM-DD"
        ).format("YYYY-MM-DD");

        this.selectedDateToParsed = dayjs(
          value.datetime_to,
          "YYYY-MM-DD"
        ).format("YYYY-MM-DD");
      }
    }
  },
  methods: {
    ...mapActions("resourceAssignment", [
      "getResourceAssignmentBreak",
      "getResourceAssignmentBreaks",
      "postResourceAssignmentBreak",
      "putResourceAssignmentBreak"
    ]),
    displayDate(date) {
      if (!date) {
        return this.displayLabelName(
          "profile",
          "assigned-resources",
          "select-date"
        );
      }
      return formatDate(date, this.globalDateFormat, true);
    },
    openDateRangePicker(tab) {
      if (tab === "from") {
        this.showDateFrom = !this.showDateFrom;
      } else {
        this.showDateTo = !this.showDateTo;
      }
    },
    selectDate(dates) {
      if (dates[0]) {
        this.selectedDateFrom = DateTime.fromISO(dates[0].eventDate).toFormat(
          "yyyy-dd-MM"
        );
        this.selectedDateFromParsed = dates[0].eventDate;
      } else {
        this.selectedDateFrom = null;
        this.selectedDateFromParsed = null;
      }
      if (dates[1]) {
        this.selectedDateTo = DateTime.fromISO(dates[1].eventDate).toFormat(
          "yyyy-dd-MM"
        );
        this.selectedDateToParsed = dates[1].eventDate;
      } else {
        this.selectedDateTo = null;
        this.selectedDateToParsed = null;
      }
    },
    initSubmit() {
      const params = {
        datetime_from: this.selectedDateFromParsed,
        datetime_to: this.selectedDateToParsed
      };

      if (this.actionType === "edit") {
        this.putResourceAssignmentBreak({
          resAssignmentId: this.resourceAssignmentId,
          breakId: this.breakId,
          params
        }).then(response => {
          if (response) {
            this.$router.push({
              name: "r_profile-edit-assigned-resources",
              params: { assignedResourceId: this.resourceAssignmentId }
            });
          }
        });
      } else {
        this.postResourceAssignmentBreak({
          resAssignmentId: this.resourceAssignmentId,
          params
        }).then(response => {
          if (response) {
            this.$router.push({
              name: "r_profile-edit-assigned-resources",
              params: { assignedResourceId: this.resourceAssignmentId }
            });
          }
        });
      }
    }
  },
  props: {
    backLinkName: {
      type: String,
      required: true
    },
    actionType: {
      type: String,
      required: false
    }
  }
};
</script>
